<template>
    <div class="row">
        <div class="col-12 mb-3">
            <b-card header="Statistics">
                <div class="p-3">
                    <b-row>
                        <b-col lg="4">
                            <v-date-picker v-model="dateRange" :model-config="modelConfig" timezone="UTC" is-range is24hr>
                                <template v-slot="{ inputValue, togglePopover }">
                                    <b-form-group>
                                        <b-input-group v-on:click="togglePopover">
                                            <b-input-group-prepend is-text>
                                                <feather-icon icon="CalendarIcon" />
                                            </b-input-group-prepend>
                                            <b-form-input :value="dateToString(inputValue)" @keypress.prevent />
                                        </b-input-group>
                                    </b-form-group>
                                </template>
                            </v-date-picker>
                        </b-col>

                        <b-col lg="3" class="mb-3">
                            <v-select
                                id="credit_type"
                                label="text"
                                :reduce="(text) => text.value"
                                placeholder="credit type"
                                v-model="queryCondition.creditType"
                                :options="[
                                    { value: 'MESON_REWARD_TEST', text: 'MESON_REWARD_TEST' },
                                    { value: 'MESON_REWARD_MAIN', text: 'MESON_REWARD_MAIN' },
                                ]"
                            />
                        </b-col>

                        <b-col lg="2" class="text-left">
                            <b-button variant="secondary" @click="query"><feather-icon icon="RotateCcwIcon" size="15" /></b-button>
                        </b-col>
                    </b-row>

                    <div>Total released:{{ releasedAmount }}</div>

                    <b-overlay :show="released_chart_show_overlay" rounded="sm" class="mt-5">
                        <vue-apex-charts ref="releasedChart" type="line" height="350" :options="releasedChartOptions" :series="releasedChartSeries" />
                    </b-overlay>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BButton,
    BOverlay,
    BBadge,
    BFormGroup,
    BFormInvalidFeedback,
    BAlert,
    BForm,
    BFormSelect,
    BFormCheckbox,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    VBTooltip,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import VueApexCharts from "vue-apexcharts";
import VDatePicker from "v-calendar/lib/components/date-picker.umd";
import moment from "moment";

export default {
    components: {
        BCard,
        BCardFooter,
        BRow,
        BCol,
        BInputGroup,
        BFormInput,
        BInputGroupPrepend,
        BButton,
        BOverlay,
        BBadge,
        BFormGroup,
        BFormInvalidFeedback,
        BAlert,
        BForm,
        BFormSelect,
        BFormCheckbox,
        VueGoodTable,
        vSelect,
        VueApexCharts,
        VDatePicker,
    },

    methods: {
        log(...arg) {
            return console.log(...arg);
        },

        dateToString(inputValue) {
            return inputValue.start + " - " + inputValue.end;
        },

        async getUserInfo() {
            let resp = await this.$api.user.getUserInfo(this.$store.state.auth.my_web_token);

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.userInfo = resp.result.user;
        },

        async query() {
            this.queryDailyReleased();
            this.queryTotalReleased();
        },

        async queryTotalReleased() {
            if (this.pageMounted == false) {
                return;
            }

            let creditType = this.queryCondition.creditType;

            this.traffic_chart_show_overlay = true;
            let resp = await this.$api.releasedReward.totalReleased(creditType, this.$store.state.auth.my_web_token);
            this.traffic_chart_show_overlay = false;

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.releasedAmount = resp.result.amount;
        },

        async queryDailyReleased() {
            if (this.pageMounted == false) {
                return;
            }

            if (this.dateRange.end.valueOf() - this.dateRange.start.valueOf() > 30 * 24 * 3600 * 1000) {
                this.$bvToast.toast("maximum date range is 30 days", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            let creditType = this.queryCondition.creditType;
            let startDate = moment(this.dateRange.start).utc().format("YYYY-MM-DD");
            let endDate = moment(this.dateRange.end).utc().format("YYYY-MM-DD");

            // console.log(userId, pullZoneId, continentCode, countryCode);
            this.traffic_chart_show_overlay = true;
            let resp = await this.$api.releasedReward.dailyReleased(creditType, startDate, endDate, this.$store.state.auth.my_web_token);
            this.traffic_chart_show_overlay = false;

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            // console.log(resp.result.traffic);

            let releasedChartData = [];
            for (let i = 0; i < resp.result.data.length; i++) {
                let obj = resp.result.data[i];
                releasedChartData.push({ x: obj.date, y: parseInt(obj.amount) });
            }

            //console.log(releasedChartData)

            this.$refs["releasedChart"].updateSeries([
                {
                    name: "Released",
                    data: releasedChartData,
                },
            ]);
        },
    },

    async mounted() {
        //page user id
        if (this.$route.params.userId) {
            let result = parseInt(this.$route.params.userId);
            if (!isNaN(result)) {
                this.pageUserId = result;
            }
        }

        this.getUserInfo();

        this.pageMounted = true;

        this.query();
    },

    data() {
        return {
            isQueryingUsage: false,
            pageMounted: false,
            userInfo: {},
            releasedAmount: "",

            queryCondition: {
                creditType: "MESON_REWARD_TEST",
            },

            //date
            dateRange: {
                start: new Date(new Date().valueOf() - 3600 * 24 * 29 * 1000),
                end: new Date(),
            },
            modelConfig: {
                start: {
                    timeAdjust: "00:00:00",
                },
                end: {
                    timeAdjust: "00:00:00",
                },
            },

            //releasedChart
            released_chart_show_overlay: false,
            releasedChartSeries: [],
            releasedChartOptions: {
                markers: {
                    size: 5,
                },
                dataLabels: {
                    enabled: false,
                    formatter: function (val, opts) {
                        if (val === 0) {
                            return "";
                        } else {
                            return val;
                        }
                    },
                },
                stroke: {
                    width: 4,
                    curve: "smooth", //'straight' 'smooth'
                    dashArray: 0,
                },
                legend: {
                    tooltipHoverFormatter: function (val, opts) {
                        return val + " - " + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + "";
                    },
                },
                xaxis: {
                    type: "category",
                },
                yaxis: {
                    labels: {
                        show: true,
                        // formatter: parserTraffic,
                    },
                },
                title: {
                    text: "Daily released",
                    align: "middle",
                },
                tooltip: {
                    y: [
                        {
                            // formatter: parserTraffic,
                        },
                    ],
                },
            },
        };
    },
};
</script>
